/**
 * Activate an expandable / collapsible tree structure in this listview table.
 *
 * @param treeParams Options to configure the tree behavior and style.
 *      All params are optional.
 *
 * @param [treeParams.autoCollapse=true] If True, hide all rows on
 *      initialization.
 *
 * @param [treeParams.rowAttrKey='data-row-id'] The name of the row attribute,
 *      to search for the current row id.
 *
 * @param [treeParams.rowParentAttrKey='data-parent-row-id'] The name of the
 *      row attribute, to search for the parent row id.
 *
 * @param [treeParams.rowLevelKey='data-tree-level'] The name of the row
 *      attribute, to use for the row level. This is a number that increases by one
 *      for each level we get deeper in the tree.
 *
 * @param [treeParams.treeHandleClass='tree_handle'] The class name to
 *      assign to the node handle. This is the symbol, shown before all cells on
 *      each row, used to collapse or expand the tree node.
 *
 * @param [treeParams.treeHandleIgnoreClass=['touchselect']] Usually the first
 *      column gets the tree handle, but columns with classes names here will never get it.
 *
 * @param [treeParams.expandChar='&#9658;'] The symbol to show on collapsed
 *      tree node handles.
 *
 * @param [treeParams.collapseChar='&#10728;'] The symbol to show on expanded
 *      tree node handles.
 *
 * @param [treeParams.rowKeysToExpand=[]] An array, maintained by the click
 *      handler, containing all node ids that are currently expanded. If there are valid
 *      node ids in this array, they get expanded on initialization. This is useful to
 *      restore the tree state e.g. after a page reload.
 *
 * @param treeParams.onChanged Function that gets called after all changes,
 *      either bei initialization or by user click, are applied.
 *
 */
import { ListView } from "./listView";

interface TreeParams {
    autoCollapse?: boolean;
    rowAttrKey?: string;
    rowParentAttrKey?: string;
    rowLevelKey?: string;
    treeHandleClass?: string;
    treeHandleIgnoreClass?: string[];
    expandChar?: string;
    collapseChar?: string;
    rowKeysToExpand?: string[];
    onChanged?: () => void;
}

export class EstablishTree {
    private readonly params: TreeParams;
    private readonly tableRows: NodeListOf<HTMLTableRowElement>;
    private readonly rowsToHide: HTMLTableRowElement[];
    private readonly nodeIndex: Record<string, HTMLTableRowElement>;
    private readonly nodeChildren: Record<string, string[]>;
    private readonly nodeParents: Record<string, string[]>;

    constructor(listView: ListView, treeParams: TreeParams) {
        this.tableRows = listView.listViewElement.querySelectorAll("table.standard-table tr");
        this.rowsToHide = [];
        this.nodeIndex = {};
        this.nodeChildren = {};
        this.nodeParents = {};
        this.params = {
            autoCollapse: true,
            rowAttrKey: "rowId",
            rowParentAttrKey: "parentRowId",
            rowLevelKey: "treeLevel",
            treeHandleClass: "tree_handle",
            treeHandleIgnoreClass: ["touchselect"],
            expandChar: "&#9658;",
            collapseChar: "&#10728;",
            rowKeysToExpand: [],
            onChanged: () => {
                /**/
            },
            ...treeParams,
        };

        // analyze the relationships
        this.tableRows.forEach((trElement) => {
            const trElementRowAttr = trElement.dataset[this.params.rowAttrKey];
            const trElementRowParentAttr = trElement.dataset[this.params.rowParentAttrKey];

            // initialize possibly empty lists
            this.nodeIndex[trElementRowAttr] = trElement;
            this.nodeChildren[trElementRowAttr] = this.nodeChildren[trElementRowAttr] || [];
            this.nodeParents[trElementRowAttr] = this.nodeParents[trElementRowAttr] || [];
            this.nodeChildren[trElementRowParentAttr] = this.nodeChildren[trElementRowParentAttr] || [];
            this.nodeParents[trElementRowParentAttr] = this.nodeParents[trElementRowParentAttr] || [];

            // push the current relation
            if (!this.nodeParents[trElementRowAttr].includes(trElementRowParentAttr)) {
                if (trElementRowParentAttr) {
                    this.nodeParents[trElementRowAttr].push(trElementRowParentAttr);
                }
            }
            if (!this.nodeChildren[trElementRowParentAttr].includes(trElementRowAttr)) {
                if (trElementRowAttr) {
                    this.nodeChildren[trElementRowParentAttr].push(trElementRowAttr);
                }
            }
        });

        // loop over all rows to make them tree nodes
        this.tableRows.forEach((trElement) => {
            const elementId = trElement.dataset[this.params.rowAttrKey];
            const parentRowId = trElement.dataset[this.params.rowParentAttrKey];

            // add space for the tree node handlers and store table relations
            if (this.nodeChildren[elementId].length > 0 || this.nodeParents[elementId].length > 0) {
                const handle = document.createElement("span");
                handle.classList.add(this.params.treeHandleClass);
                handle.addEventListener("click", () => {
                    this.toggleNodeVisibility(trElement);
                    if (typeof this.params.onChanged === "function") {
                        this.params.onChanged();
                    }
                });
                Array.from(trElement.children)
                    .filter((tdElement: HTMLTableCellElement) => {
                        for (let index = 0; index < this.params.treeHandleIgnoreClass.length; index++) {
                            if (tdElement.classList.contains(this.params.treeHandleIgnoreClass[index])) return false;
                        }
                        return true;
                    })?.[0]
                    ?.prepend(handle);
            }

            // for autoCollapse initially mind all child rows with an existing parent node to hide
            if (
                parentRowId &&
                this.params.autoCollapse &&
                this.nodeParents[elementId].length &&
                !this.params.rowKeysToExpand.includes(parentRowId) &&
                this.nodeIndex[parentRowId] instanceof HTMLTableRowElement
            ) {
                this.rowsToHide.push(trElement);
            }
        });

        // finally hide the nodes
        this.rowsToHide.forEach((trElement) => {
            trElement.style.display = "none";
        });

        // initialize the tree node handler states and set the row levels
        // (this is delayed, to speed up the drawing of the actual table)
        setTimeout(() => {
            this.tableRows.forEach((trElement) => {
                this.maintainNodeLevel(trElement);
                this.maintainNodeHandle(trElement);
            });
        }, 0);
    }

    /**
     * Collapse or expand a single node.
     *
     * @param trElement The row to update.
     * @param hide If true, hide the element, independent
     *      whether its currently visible or hidden.
     **/
    public toggleNodeVisibility = (trElement: HTMLTableRowElement, hide = false) => {
        const elementId = trElement.dataset[this.params.rowAttrKey];

        if (this.nodeChildren[elementId]) {
            this.nodeChildren[elementId].forEach((subElementId) => {
                const subElement = this.nodeIndex[subElementId];
                const parentId = subElement.dataset[this.params.rowParentAttrKey];

                if (parentId === elementId) {
                    // hide or show the sub rows, dependent of their current visibility
                    if (hide === true || subElement.style.display !== "none") {
                        subElement.style.display = "none";

                        // on hide, also hide all sub elements
                        this.toggleNodeVisibility(subElement, true);

                        // maintain the list of expanded rows
                        this.params.rowKeysToExpand.forEach((value, index) => {
                            if (value === elementId) {
                                this.params.rowKeysToExpand.splice(index, 1);
                                return false;
                            }
                        });
                    } else {
                        subElement.style.display = "";

                        // maintain the list of expanded rows
                        if (!this.params.rowKeysToExpand.includes(elementId)) {
                            this.params.rowKeysToExpand.push(elementId);
                        }
                    }

                    // after changing the visibilities, switch the tree node handles
                    this.maintainNodeHandle(trElement);
                }
            });
        }
    };

    /**
     * Set or update the node handle for the given row element.
     *
     * @param trElement The row to update.
     **/
    private maintainNodeHandle = (trElement: HTMLTableRowElement) => {
        const elementId = trElement.dataset[this.params.rowAttrKey];

        if (this.nodeChildren[elementId]) {
            this.nodeChildren[elementId].forEach((subElementId) => {
                const subElement = this.nodeIndex[subElementId];

                if (subElement.style.display !== "none") {
                    trElement.querySelector(`.${this.params.treeHandleClass}`).innerHTML = this.params.collapseChar;
                } else {
                    trElement.querySelector(`.${this.params.treeHandleClass}`).innerHTML = this.params.expandChar;
                }

                return false;
            });
        }
    };

    /**
     * Set the level for the given tree node.
     *
     * @param trElement The row to update.
     **/
    private maintainNodeLevel = (trElement: HTMLTableRowElement) => {
        const elementId = trElement.dataset[this.params.rowAttrKey];

        // higher the current level or initially set it to 1
        const intialLevel = trElement.dataset[this.params.rowLevelKey];
        trElement.dataset[this.params.rowLevelKey] = intialLevel ? String(parseInt(intialLevel, 10) + 1) : "1";

        // iterate over all sub rows and increase
        if (this.nodeChildren[elementId]) {
            this.nodeChildren[elementId].forEach((subElementId) => {
                this.maintainNodeLevel(this.nodeIndex[subElementId]);
            });
        }
    };
}
